import React from 'react';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <div className="landing-content">
        <img 
          src="/images/TobiLogo.png" 
          alt="Tobi Logo" 
          className="landing-logo"
        />
        <h1>Mobile Only Experience</h1>
        <p>Please access this site from a mobile device.</p>
      </div>
    </div>
  );
};

export default LandingPage; 