import React from 'react';
import './PlayMenuBar.css';

const PlayMenuBar = ({ progress, onPlayClick }) => {
  return (
    <div className="play-menu-bar">
      <div className="loading-text">Loading game...</div>
      <div className="progress-bar-container">
        <div 
          className="progress-bar" 
          style={{ width: `${progress}%` }}
        />
      </div>
      <button 
        className={`play-button ${progress === 100 ? 'enabled' : ''}`}
        onClick={onPlayClick}
        disabled={progress < 100}
      >
        Play
      </button>
    </div>
  );
};

export default PlayMenuBar; 