import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const MobileOnlyRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update width and handle navigation
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
      
      if (newWidth > 1024 && location.pathname !== '/landing') {
        // Redirect to landing if screen becomes too wide
        navigate('/landing');
      } else if (newWidth <= 1024 && location.pathname === '/landing') {
        // Redirect to home if screen becomes mobile-friendly
        navigate('/');
      }
    };

    // Initial check
    if (windowWidth > 1024 && location.pathname !== '/landing') {
      navigate('/landing');
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [navigate, location.pathname]);

  return null; // This component doesn't render anything
};

export default MobileOnlyRedirect; 