// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import GamePage from './pages/GamePage';
import PlayPage from './pages/PlayPage';
import LandingPage from './pages/LandingPage';
import MobileOnlyRedirect from './components/MobileOnlyRedirect';

function App() {
  return (
    <Router>
      <MobileOnlyRedirect />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/game/:title" element={<GamePage />} /> {/* Use :title to capture the slug */}
        <Route path="/game/:title/play" element={<PlayPage />} />
        <Route path="/landing" element={<LandingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
