// src/pages/HomePage.js
import React from 'react';
import GameCard from '../components/GameCard';
import MenuBar from '../components/MenuBar';
import { createSlug } from '../utils/slug'; // Import slug creation utility
import './HomePage.css';

const HomePage = () => {
  const games = [
    {
      id: 1,
      title: "Star Treasure",
      genre: "Adventure",
      gameplayImage: "/images/star-treasure-gameplay.png",
      iconImage: "/images/star-treasure-icon.jpg",
    },
    {
      id: 2,
      title: "Jump It",
      genre: "Platformer",
      gameplayImage: "/images/jump-it-gameplay.jpg",
      iconImage: "/images/jump-it-icon.jpg",
    },
    {
      id: 3,
      title: "Spike Mania",
      genre: "Action",
      gameplayImage: "/images/spike-mania-gameplay.jpg",
      iconImage: "/images/spike-mania-icon.jpg",
    },
    {
      id: 4,
      title: "Planet Trivia",
      genre: "Trivia",
      gameplayImage: "/images/planet-trivia-gameplay.jpg",
      iconImage: "/images/planet-trivia-icon.jpg",
    },
    {
      id: 5,
      title: "Count Down",
      genre: "Puzzle",
      gameplayImage: "/images/count-down-gameplay.jpg",
      iconImage: "/images/count-down-icon.png",
    },
    {
      id: 6,
      title: "Peek Away",
      genre: "Stealth",
      gameplayImage: "/images/peek-away-gameplay.jpg",
      iconImage: "/images/peek-away-icon.jpg",
    },
    {
      id: 7,
      title: "Marble Run",
      genre: "Racing",
      gameplayImage: "/images/marble-run-gameplay.jpg",
      iconImage: "/images/marble-run-icon.jpg",
    },
  ];

  return (
    <div className="home-page">
      <MenuBar />

      <h1 className="games-title">Browse Games:</h1>

      <div className="game-card-grid">
        {games.map((game) => (
          <GameCard
            key={game.id}
            game={{
              ...game,
              slug: createSlug(game.title), // Generate slug for the title
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default HomePage;
