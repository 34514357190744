// src/components/GameCard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './GameCard.css';

const GameCard = ({ game }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/game/${game.slug}`); // Use slug in the URL
  };

  return (
    <div className="game-card" onClick={handleCardClick}>
      <img src={game.gameplayImage} alt={`${game.title} gameplay`} className="game-image" />
      <div className="game-info">
        <div className="game-text">
          <div className="game-title">{game.title}</div>
          <div className="game-genre">{game.genre}</div>
        </div>
        <img src={game.iconImage} alt={`${game.title} icon`} className="game-icon" />
      </div>
    </div>
  );
};

export default GameCard;
