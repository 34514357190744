// src/components/MenuBar.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './MenuBar.css';

const MenuBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleSaveProgress = () => {
    alert('Coming Soon!');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="menu-bar">
      {/* Logo */}
      <img
        src="/images/TobiLogo.png"
        alt="Tobi Logo"
        className="menu-logo"
        onClick={handleLogoClick}
      />

      {/* Save My Progress */}
      <div className="save-progress" onClick={handleSaveProgress}>
        Save My Progress
      </div>

      {/* Menu Icon */}
      <div className="menu-icon" onClick={toggleMenu}>
        <div className="menu-bar-line"></div>
        <div className="menu-bar-line"></div>
        <div className="menu-bar-line"></div>
      </div>

      {/* Popup Menu */}
      {menuOpen && (
        <div className="menu-popup">
          <button className="menu-close" onClick={toggleMenu}>X</button>
          <ul className="menu-list">
            <li>
              <a href="/terms-of-service">Terms of Service</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MenuBar;
