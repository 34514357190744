import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PlayMenuBar from '../components/PlayMenuBar';
import './PlayPage.css';

const PlayPage = () => {
  const { title } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [showOverlay, setShowOverlay] = useState(true);

  const handleBack = () => {
    navigate(`/game/${title}`);
  };

  // Simulate loading progress
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 50); // 5000ms (5 seconds) / 100 steps = 50ms per step

    return () => clearInterval(interval);
  }, []);

  const handlePlayClick = () => {
    if (progress === 100) {
      setShowOverlay(false);
    }
  };

  return (
    <div className="play-page">
      {showOverlay && (
        <div className="loading-overlay">
          <PlayMenuBar 
            progress={progress} 
            onPlayClick={handlePlayClick} 
          />
          <div className="ad-container">
            <img 
              src="/images/banner-ad.jpg" 
              alt="Advertisement" 
              className="ad-banner"
            />
          </div>
        </div>
      )}
      <div className="game-container">
        <button className="back-button" onClick={handleBack}>
          Back to Game
        </button>
        <div className="game-placeholder">
          Game Content Will Appear Here
        </div>
      </div>
    </div>
  );
};

export default PlayPage; 