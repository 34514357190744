// src/pages/GamePage.js
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createSlug } from '../utils/slug';
import MenuBar from '../components/MenuBar';
import { ReactComponent as TrophyIcon } from '../svg/trophy.svg';
import { ReactComponent as PeopleIcon } from '../svg/people.svg';
import { ReactComponent as BoxIcon } from '../svg/box.svg';
import './GamePage.css';

const GamePage = () => {
  const { title } = useParams();
  const navigate = useNavigate();

  // Games data
  const games = [
    {
      id: 1,
      title: "Star Treasure",
      genre: "Adventure",
      description: "Discover the thrill of Star Treasure – an exhilarating 30-second challenge where every moment counts! Race against the clock to collect silver and dazzling golden stars, all while avoiding spike balls. Score points with each star you secure and aim for the highest score to claim victory. Are you ready to embark on this fast-paced treasure hunt?",
      rank: "#1",
      players: "200k",
      gameplayImage: "/images/star-treasure-gameplay.png",
      headerImage: "/images/star-treasure-header.png",
    },
    {
      id: 2,
      title: "Jump It",
      genre: "Platformer",
      description: "Jump It is a high-stakes minigame that ramps up the tension as the seconds tick away. When the ccounting begins, players must react quickly to avoid falling down. Executing precise moves—to avoid being caught off guard as time runs out or eliminate your opponents. The pressure builds with every tick, testing your reflexes, precision, and nerves under fire. Only the most agile and focused competitors can pull off the perfect final move to secure victory in this pulse-pounding showdow.",
      rank: "#3",
      players: "150k",
      gameplayImage: "/images/jump-it-gameplay.jpg",
      headerImage: "/images/jump-it-header.jpg",
    },
    {
      id: 3,
      title: "Spike Mania",
      genre: "Action",
      description: "This game is about staying on the correct tile. Make a mistake and you'll fall down along with the other losers! Last to stand wins, or survive for 30 seconds. Up to you.",
      rank: "#5",
      players: "120k",
      gameplayImage: "/images/spike-mania-gameplay.jpg",
      headerImage: "/images/spike-mania-header.jpg",
    },
    {
      id: 4,
      title: "Planet Trivia",
      genre: "Trivia",
      description: "Test your knowledge about planets! We'll tell you where to go, and you have to make sure you go there. You ain't making it to the end.",
      rank: "#2",
      players: "250k",
      gameplayImage: "/images/planet-trivia-gameplay.jpg",
      headerImage: "/images/planet-trivia-header.jpg",
    },
    {
      id: 5,
      title: "Count Down",
      genre: "Puzzle",
      description: "Coming Soon!",
      rank: "#N/A",
      players: "0",
      gameplayImage: "/images/count-down-gameplay.jpg",
      headerImage: "/images/count-down-header.jpg",
    },
    {
      id: 6,
      title: "Peek Away",
      genre: "Stealth",
      description: "Coming Soon!",
      rank: "#N/A",
      players: "0",
      gameplayImage: "/images/peek-away-gameplay.jpg",
      headerImage: "/images/peek-away-header.jpg",
    },
    {
      id: 7,
      title: "Marble Run",
      genre: "Racing",
      description: "Coming Soon!",
      rank: "#N/A",
      players: "0",
      gameplayImage: "/images/marble-run-gameplay.jpg",
      headerImage: "/images/marble-run-header.jpg",
    },
  ];

  const game = games.find((g) => createSlug(g.title) === title);

  if (!game) {
    return <p>Game not found</p>;
  }

  const handleBack = () => {
    navigate('/');
  };

  const handlePlayClick = () => {
    navigate(`/game/${title}/play`);
  };

  return (
    <div className="game-page">
      <MenuBar />

      {/* Header Image */}
      <div className="header-image-container">
        <img src={game.headerImage} alt={`${game.title} header`} className="header-image" />
      </div>

      {/* Title Pill */}
      <div className="game-title-pill">
        <span>{game.title}</span>
      </div>

      {/* Buttons - moved up */}
      <div className="button-container">
        <button className="game-play-button" onClick={handlePlayClick}>Play</button>
        <button className="game-multiplayer-button">Multiplayer</button>
      </div>

      {/* Info Boxes - moved to middle */}
      <div className="info-boxes">
        {/* Ranking Box */}
        <div className="info-box">
          <TrophyIcon className="info-icon" />
          <p className="info-text">Ranking</p>
          <p className="info-subtext">{game.rank}</p>
        </div>
        {/* Players Box */}
        <div className="info-box">
          <PeopleIcon className="info-icon" />
          <p className="info-text">Players</p>
          <p className="info-subtext">{game.players}</p>
        </div>
        {/* Genre Box */}
        <div className="info-box">
          <BoxIcon className="info-icon" />
          <p className="info-text">Genre</p>
          <p className="info-subtext">{game.genre}</p>
        </div>
      </div>

      {/* Description - moved to bottom */}
      <div className="about-section">
        <p className="about-text">{game.description}</p>
      </div>
    </div>
  );
};

export default GamePage;
